// TODO: in futuro usare site settimngs su plone per gestire il logo
import LogoEmilia from '~/components/Layout/logoEmilia.png';
import LogoEmiliaNatale from '~/components/Layout/logo-emilia-natale.png';
import React from 'react';
import { Helmet } from '@plone/volto/helpers';
import { getSiteProperty } from '@italia/helpers';
import { useIntl } from 'react-intl';

/*
 * Customization with image
 *
 * If you have a jpg/png, do like this:
 *
 * <figure className="icon">
 *  <img src={logo} alt="" />
 * </figure>
 *
 * Note the icon class.
 */
const isChristmas = () => {
  const now = new Date();

  if (now.getMonth() === 11) {
    //is december
    if (now.getDate() >= 5) {
      return true;
    }
  }
  if (now.getMonth() === 0) {
    //is january
    if (now.getDate() <= 6) {
      return true;
    }
  }
  return false;
};
const Logo = () => {
  const intl = useIntl();
  // TODO: questo andrebbe solo nella homepage, ora per velocità è messo qui e lo
  // prendono tutte le pagine con il footer
  const schemaorg = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: "Camera di Commercio dell'Emilia",
    url: 'https://www.emilia.camcom.it/',
  };
  let LogoImg = LogoEmilia;
  // if (isChristmas()) {
  //   LogoImg = LogoEmiliaNatale;
  // }
  return (
    <>
      <img
        src={LogoImg}
        width="279"
        height="82"
        className="icon logo-emilia"
        alt={getSiteProperty('siteTitle', intl.locale)}
      />
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(schemaorg)}</script>
      </Helmet>
    </>
  );
};
export default Logo;
